import './websites.styles.scss';

const Websites = () => {
return (

  <div className="websites">
      <div className="websites-header">
        <h1>Websites</h1>
      </div>
      <div className='websites-hero'>
        <div className='slideText'> How do you make a website? </div>
    
    </div>
  </div>
)
}

export default Websites;
